//@flow

import React from 'react';

import SEO from '../../components/seo';
import { PrivateRoute } from '../../services/auth';
// import { getUrlVars } from '../../utils';

import IntroScreen from '../../screens/Intro';

const QuizzPage = () => {

  return (
    <>
      <SEO title="Intro" />
      <IntroScreen
        
      />
    </>
  );
};

export default PrivateRoute(QuizzPage);
