import React, { useState } from 'react';
import { Link, FormattedMessage, useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';

import { Title } from '../components/typo';
import { Question } from '../components/quizz';
import Episode from '../components/items/Episode';
import { programs as programsLocales, intro as introLocales } from '../datas';

import useGlobal from '../store';

import {
  Wrapper,
} from './Intro.style';

type Props = {
  cover: any,
  chapterNumber: Number,
  episodeNumber: Number,
};

const RenderEpisode = ({
  cover,
  chapterNumber,
  episodeNumber
}: Props) => {
  const intl = useIntl();
  const programs = programsLocales[intl.locale];
  const episode = programs[chapterNumber-1].episodes[episodeNumber-1];
  const [chapterState, setEpisodeProgress] = useGlobal(
    state => (state.chapters || []).find(c => c.number === chapterNumber),
    actions => actions.setEpisodeProgress,
  );

  const episodeState = (chapterState && chapterState.episodes || [])
    .find(e => e.number === episodeNumber) || {};

  return (
    <>
      <Episode
        title={episode.title}
        description={episode.description}
        number={episode.number}
        poster={cover}
        video={episode.video}
        viewed={episodeState.progress === 1}
        inprogress={episodeState.progress}
        collapsed={false}
        hideEpisodeNumber
        onVideoEnded={(progress) => {
          const episodeProgress = progress > 0.8 ? 1 : progress;
          setEpisodeProgress(chapterNumber, episodeNumber, episodeProgress);
        }}
      />
      <Link
        className="button"
        to={`/app/chapter/${chapterNumber}`}
      >
        <FormattedMessage id="program.next" />
      </Link>
      <Link
        className="button outline"
        to={`/app/program`}
      >
        <FormattedHTMLMessage id="intro.start-program" />
      </Link>
    </>
  )
}

const IntroScreen = () => {
  const intl = useIntl();
  const intro = introLocales[intl.locale];

  const images = useStaticQuery(
    graphql`
      query IntroGetImages($maxWidth: Int) {
        ep2_1: file(relativePath: { eq: "video/thumb3.png" }) {
          ...defaultImage
        }
        ep3_1: file(relativePath: { eq: "video/thumb7.png" }) {
          ...defaultImage
        }
        ep4_1: file(relativePath: { eq: "video/thumb9.png" }) {
          ...defaultImage
        }
        ep5_1: file(relativePath: { eq: "video/thumb15.png" }) {
          ...defaultImage
        }
        ep1_1: file(relativePath: { eq: "video/thumb1.png" }) {
          ...defaultImage
        }
      }
    `,
    {
      maxWidth: 420,
    }
  );

  const [_, setIntroDone] = useGlobal(
    () => false,
    actions => actions.setIntroDone,
  );

  const [showResult, setShowResult] = useState({
    display: false,
    chapterNumber: false,
    episodeNumber: false,
  });

  const titleId = showResult.display ?
    intl.formatMessage({ id: 'intro.reco-title'}) :
    intl.formatMessage({ id: 'intro.title' });

  return (
    <Wrapper>
      <Link
        className="back-link"
        to={`/app/program`}
      >
        <span className="icon">&lt;</span><FormattedMessage id="program.back-program" />
      </Link>
      <Title
        t={titleId}
      />
      {showResult.display ? (
        <RenderEpisode
          chapterNumber={showResult.chapterNumber}
          episodeNumber={showResult.episodeNumber}
          cover={images[`ep${showResult.chapterNumber}_${showResult.episodeNumber}`]}
        />
      ) : (
        <Question
          quizz={intro}
          singleChoice
          hideHints
          onValidate={(success, score, responses) => {
            const respIndex = responses.pop();
            const result = intro.options.find(o => o.id === respIndex);
            setIntroDone();
            setShowResult({
              display: true,
              chapterNumber: result.chapterNumber,
              episodeNumber: result.episodeNumber,
            });
          }}
        />
      )}
    </Wrapper>
  );
};

IntroScreen.defaultProps = {
};

export default IntroScreen;
